import { Connection, ConnectionType } from 'connection/types'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet } from 'state/userWallet/reducer'

import { didUserReject } from './utils'
import { ChainId } from 'constants/chain'
import { useWeb3React } from '@web3-react/core'

export enum ActivationStatus {
  PENDING,
  ERROR,
  IDLE
}

type ActivationPendingState = { status: ActivationStatus.PENDING; connection: Connection }
type ActivationErrorState = { status: ActivationStatus.ERROR; connection: Connection; error: any }
const IDLE_ACTIVATION_STATE = { status: ActivationStatus.IDLE } as const
type ActivationState = ActivationPendingState | ActivationErrorState | typeof IDLE_ACTIVATION_STATE

const activationStateAtom = atom<ActivationState>(IDLE_ACTIVATION_STATE)

function useTryActivation() {
  const dispatch = useAppDispatch()
  const setActivationState = useUpdateAtom(activationStateAtom)
  // const { pathname } = useLocation()
  // const currentPage = getCurrentPageFromLocation(pathname)

  return useCallback(
    async (connection: Connection, onSuccess: () => void, chainId?: ChainId) => {
      // Skips wallet connection if the connection should override the default
      // behavior, i.e. install MetaMask or launch Coinbase app
      if (connection.overrideActivate?.(chainId)) return

      try {
        setActivationState({ status: ActivationStatus.PENDING, connection })

        console.debug(`Connection activating: ${connection.getName()}`)
        dispatch(updateSelectedWallet({ wallet: undefined }))
        console.debug('Connection activating', connection.connector)
        await connection.connector.activate()

        console.debug(`Connection activated: ${connection.getName()}`)
        dispatch(updateSelectedWallet({ wallet: connection.type }))

        // Clears pending connection state
        setActivationState(IDLE_ACTIVATION_STATE)

        if (connection.type === ConnectionType.WALLET_CONNECT_V2) {
          window.location.reload()
        }

        onSuccess()
      } catch (error) {
        // Gracefully handles errors from the user rejecting a connection attempt
        if (didUserReject(connection, error)) {
          setActivationState(IDLE_ACTIVATION_STATE)
          return
        }

        // TODO(WEB-1859): re-add special treatment for already-pending injected errors & move debug to after didUserReject() check
        console.debug(`Connection failed: ${connection.getName()}`)
        console.error(error)

        setActivationState({ status: ActivationStatus.ERROR, connection, error })
      }
    },
    [dispatch, setActivationState]
  )
}

function useCancelActivation() {
  const setActivationState = useUpdateAtom(activationStateAtom)
  const dispatch = useAppDispatch()

  return useCallback(
    () =>
      setActivationState(activationState => {
        if (activationState.status !== ActivationStatus.IDLE) {
          activationState.connection.connector.deactivate?.()
        }
        dispatch(updateSelectedWallet({ wallet: undefined }))
        return IDLE_ACTIVATION_STATE
      }),
    [dispatch, setActivationState]
  )
}

export function useActivationState() {
  const activationState = useAtomValue(activationStateAtom)
  const tryActivation = useTryActivation()
  const cancelActivation = useCancelActivation()

  return { activationState, tryActivation, cancelActivation }
}

export function useWalletDeactivate() {
  const dispatch = useAppDispatch()
  const { connector } = useWeb3React()

  return useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }, [connector, dispatch])
}
