import { createReducer } from '@reduxjs/toolkit'
import { saveUserInfo, removeUserInfo, UserInfo } from './actions'

export interface UserState {
  addressInfo: UserInfo
}

// let data
// if (localStorage.getItem('localUserInfo')) {
//   data =
//     localStorage.getItem('localUserInfo') === 'undefined'
//       ? {
//           headPhoto: '',
//           name: ''
//         }
//       : JSON.parse(localStorage.getItem('localUserInfo') ?? '')
// }

export const initialState: UserState = {
  addressInfo: {
    headPhoto: '',
    name: ''
  }
}

export default createReducer(initialState, builder =>
  builder
    .addCase(removeUserInfo, state => {
      state.addressInfo = {
        name: '',
        headPhoto: ''
      }
      localStorage.setItem('localUserInfo', JSON.stringify({}))
    })
    .addCase(saveUserInfo, (state, action) => {
      const { userInfo } = action.payload
      state.addressInfo = userInfo
      localStorage.setItem('localUserInfo', JSON.stringify(userInfo))
    })
)
