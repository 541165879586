import { createAction } from '@reduxjs/toolkit'
import { ChainId } from 'constants/chain'
import { ActivityStatus } from 'hooks/useBackedActivityServer'
import { ActivityType } from 'hooks/useBackedActivityServer'

export interface HomeListPaginationProp {
  keyword: string
  category: string
  currentPage: number
}

export interface ActivityListPaginationProp {
  chainId: ChainId | undefined
  types: ActivityType | undefined
  status: ActivityStatus | undefined
  currentPage: number
  title: string | undefined
  rewardType: number | undefined
  keyword: string
  categories: string | undefined
}
export interface myActivityListPaginationProp {
  currentPage: number
}

export interface SbtListPaginationProp {
  chainId: ChainId | undefined
  status: ActivityStatus | undefined | string
  currentPage: number
  category: number
}

export interface NotificationListPaginationProp {
  unReadCount: number
  currentPage: number
}

export const updateHomeListPagination = createAction<{ homeListPagination: HomeListPaginationProp }>(
  'pagination/updateHomeListPagination'
)

export const updateActivityListPagination = createAction<{ activityListPagination: ActivityListPaginationProp }>(
  'pagination/updateActivityListPagination'
)

export const updateMyActivityListPagination = createAction<{ myActivityListPagination: ActivityListPaginationProp }>(
  'pagination/updateActivityListPagination'
)

export const updateSbtListPagination = createAction<{ sbtListPagination: SbtListPaginationProp }>(
  'pagination/updateSbtListPagination'
)

export const updateNotificationListPagination = createAction<{
  notificationListPagination: NotificationListPaginationProp
}>('pagination/updateNotificationListPagination')
